import {createRouter, createWebHistory} from 'vue-router';
import AdminLayout from "@/components/layouts/AdminLayout";
import PageNotFound from "@/pages/PageNotFound";
import PaymentNotFound from "@/pages/PaymentNotFound";

const routes = [
    {
        path: '/login',
        component: () => import('../pages/Login'),
        name: 'home'
    },
    {
        path: '/',
        redirect: '/admin',
        name: 'index'
    },
    {
        path: '/update-password',
        component: () => import('../pages/UpdatePassword'),
        name: 'updatePassword'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'pageNotFound',
        component: PageNotFound
    },
    {
        path: "/admin",
        name: "admin",
        component: AdminLayout,
        redirect: "/admin/dashboard",
        beforeEnter: guardRouter,
        children: [
            {
                path: 'dashboard',
                component: () => import('../components/Dashboard.vue')
            },
            {
                path: 'agenda-view',
                name: 'agenda-view',
                component: () => import('../components/clinical/agendamento/agendamento-calendar')
            },
            {
                path: 'agendar',
                name: 'agendar',
                component: () => import('../components/clinical/agendar/agendar')
            },
            {
                path: 'agendamento-status',
                name: 'agendamento-status',
                component: () => import('../components/clinical/agendamento/status/agendamento-status-tabs')
            },
            {
                path: 'despesas-list',
                name: 'desapesas-list',
                component: () => import('../components/clinical/despesas/despesa-list')
            },
            {
                path: 'despesa-tab',
                name: 'despesa-tab',
                component: () => import('../components/clinical/despesas/despesa-tabs'),
                redirect: "/despesa/step1",
                children: [
                    {
                        path: '/despesa/step1',
                        component: () => import('../components/clinical/despesas/despesa-step1')
                    },
                    {
                        path: '/despesa/step2',
                        component: () => import('../components/clinical/despesas/despesa-step2')
                    },
                    {
                        path: '/despesa/step3',
                        component: () => import('../components/clinical/despesas/despesa-step3')
                    }
                ]
            },
            {
                path: 'produto',
                name: 'produtos',
                component: () => import('../components/clinical/produtos/produto-list')
            },
            {
                path: 'produto/create',
                name: 'produto',
                component: () => import('../components/clinical/produtos/produto-create')
            },
            {
                path: 'procedimento',
                name: 'procedimentos',
                component: () => import('../components/clinical/procedimentos/procedimento-list')
            },
            {
                path: 'procedimento/create',
                name: 'procedimento',
                component: () => import('../components/clinical/procedimentos/procedimento-create')
            },
            {
                path: 'contato',
                name: 'contatos',
                component: () => import('../components/clinical/contatos/contato-list.vue')
            },
            {
                path: 'contato/create',
                name: 'contato',
                component: () => import('../components/clinical/contatos/contato-create.vue')
            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('../components/clinical/profile/profile-user')
            },
            {
                path: 'admin-profile',
                name: 'admin-profile',
                component: () => import('../components/clinical/profile/admin/admin-profile')
            },
            {
                path: 'update-password',
                name: 'update-password',
                component: () => import('../components/clinical/profile/update-password')
            },
            {
                path: 'users',
                name: 'users',
                component: () => import('../components/clinical/usuarios/users.vue')
            },
            {
                path: 'user',
                name: 'user',
                component: () => import('../components/clinical/usuarios/user')
            },
            {
                path: '/payment-not-found',
                name: 'paymentNotFound',
                component: PaymentNotFound
            },
        ]
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

// eslint-disable-next-line no-unused-vars
function guardRouter(to, from, next) {
    let isAuthenticated = !! localStorage.getItem('access_token');
    if(isAuthenticated) {
        next();
    }
    else {
        next('/login');
    }
}
export default router;
