<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img v-if="!isLogoCustomer" src="@/assets/logo.png" alt=""/>
      <Avatar v-if="isLogoCustomer" :image="clinical.urlLogo" size="large" shape="circle"/>
      <span>{{clinical.name}}</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <a @click="toggleNotification" v-ripple class="flex p-3 px-5 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
          <i class="pi pi-bell mt-1 text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger="notificationsBadge"></i>
          <span class="block lg:hidden font-medium">Notifications</span>
          <OverlayPanel ref="opNot" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '500px'}">
            <DataTable :value="notifications" selectionMode="single" :paginator="true" :rows="5" responsiveLayout="scroll" >
              <Column style="width: 70%">
                <template #body="slotProps">
                  <a @click.prevent="updateNotification(slotProps.data)" v-if="!slotProps.data.read" class="text-sm"><b>{{ slotProps.data.message}}</b></a>
                  <a v-if="slotProps.data.read">{{ slotProps.data.message}}</a>
                </template>
              </Column>
              <Column style="width: 30%">
                <template #body="slotProps">
                  <span class="text-sm">{{moment(slotProps.data.dtSender).fromNow()}}</span>
                </template>
              </Column>
            </DataTable>
          </OverlayPanel>
        </a>
      </li>
      <li class="border-top-1 surface-border lg:border-top-none">
        <a @click="toggle" v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
          <Avatar icon="pi pi-user" v-if="!user.urlImage" class="mr-2" size="large" style="background-color:#2196F3; color: #ffffff" shape="circle"/>
          <Avatar :image="user.urlImage" v-else class="mr-2" size="large" style="background-color:#2196F3; color: #ffffff" shape="circle"/>
        </a>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </li>
    </ul>
  </div>
</template>

<script>

import NotificationService from "@/service/NotificationService";
import moment from "moment/moment";

export default {
  data() {
    return {
      items: [
        {
          label: 'Perfil ',
          items: [
            {
              label: 'Editar Clínica',
              icon: 'pi pi-building',
              command: () => {
                this.$router.push('admin-profile');
              }
            },
            {
              label: 'Editar Perfil',
              icon: 'pi pi-user-edit',
              command: () => {
                this.$router.push('profile');
              }
            },
            {
              label: 'Alterar Senha',
              icon: 'pi pi-key',
              command: () => {
                this.$router.push('update-password');
              }
            },
            {
              label: 'Sair',
              icon: 'pi pi-sign-out',
              command: () => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("user");
                localStorage.removeItem("clinical");
                localStorage.removeItem("clinicalId");
                this.$router.push({name: 'home'});
              }
            }
          ]}
      ],
      clinical: {},
      user: {},
      notificationService: null,
      notifications: [],
      notificationsBadge: 0,
      isLogoCustomer: true
    }
  },
  created() {
    this.moment = moment;
    moment.locale('pt-BR');
    this.notificationService = new NotificationService();
    this.clinical = JSON.parse(localStorage.getItem("clinical"));
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  async mounted() {
    if(!this.clinical.urlLogo) {
      this.isLogoCustomer = false;
      this.clinical.urlLogo = '@/assets/logo.png';
    }
    await this.loadNotification();
    await this.countNotification();
  },
  methods: {

    async loadNotification() {
      const response = await this.notificationService.findAll();
      if(response.status === 200) {
        this.notifications = response.data;
      }
    },
    async updateNotification(notification) {
      if (!notification.read) {
        notification.read = true;
        const response = await this.notificationService.save(notification);
        if (response === 200) {
          await this.loadNotification();
          await this.countNotification();
          this.$forceUpdate();
        }
      }
    },

    async countNotification() {
      const response = await this.notificationService.countNotification();
      if(response.status === 200) {
        this.notificationsBadge = response.data;
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    toggleNotification(event) {
      this.$refs.opNot.toggle(event);
    },
    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event);
    },
    topbarImage() {
      const image = '@/assets/logo.png';
      return this.clinical.urlLogo ? image : image;
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  }
}
</script>

<style lang="scss" scoped>

.badge {
  background-color: red;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

</style>
