import api from '../configs/api';

export default class NotificationService {

    async findAll() {
        return api.get(`/admin/notification`);
    }

    async countNotification() {
        return api.get(`/admin/notification/count`);
    }

    save(notification) {
        return api.post(`/admin/notification`, notification);
    }

}