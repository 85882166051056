import axios from "axios";


export default class BrasilService {

    async findEstados() {
        return axios.get(`https://brasilapi.com.br/api/ibge/uf/v1`);
    }

    async findById(code) {
        return axios.get(`https://brasilapi.com.br/api/ibge/uf/v1/${code}`);
    }

    async findByCnpj(cnpj) {
        return axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`);
    }

}
