<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menuPermission" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "@/vue-app/AppTopbar";
import AppMenu from "@/vue-app/AppMenu";
import AppFooter from "@/vue-app/AppFooter";
import _ from "lodash"

export default {
  name: "AdminLayout",
  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      userRoles: [],
      menuPermission: [],
      menu : [
        {
          label: 'Home',
          roles: ['ROLE_ADMIN'],
          items: [{
            label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/admin/dashboard'
          }]
        },
        {
          label: 'Agenda', icon: 'pi pi-fw pi-sitemap',
          roles: ['ROLE_ADMIN', 'ROLE_ATENDIMENTO', 'ROLE_ESPECIALISTA'],
          items: [
            {label: 'Minha Agenda', icon: 'pi pi-fw pi-calendar', to: '/admin/agenda-view'},
            {label: 'Novo Agendamento', icon: 'pi pi-fw pi-calendar-plus', to: '/admin/agendar'},
          ]
        },
        {
          label: 'Despesas', icon: 'pi pi-fw pi-sitemap',
          roles: ['ROLE_ADMIN', 'ROLE_ATENDIMENTO'],
          items: [
            {label: "Compras", icon: "pi pi-fw pi-shopping-cart", to: "/admin/despesas-list"}
          ]
        },
        {
          label: 'Cadastro Base',
          roles: ['ROLE_ADMIN'],
          items: [
            {label: 'Insumos', icon: 'pi pi-fw pi-sliders-v', to: '/admin/produto'},
            {label: 'Procedimentos', icon: 'pi pi-fw pi-pencil', to: '/admin/procedimento'},
            {label: 'Contatos', icon: 'pi pi-fw pi-users', to: '/admin/contato'}
          ]
        },
        {
          label: 'Administrativo',
          roles: ['ROLE_ADMIN'],
          items: [
            {label: 'Usuários', icon: 'pi pi-fw pi-user-edit', to: '/admin/users'}
            // {label: 'Habilidades', icon: 'pi pi-fw pi-sliders-v', to: '/admin/habilidade', badge: " TESTAR"}
          ]
        }
      ]
    }
  },
  mounted() {
    this.userRoles = JSON.parse(localStorage.getItem("user")).roles;
    this.verifyMenu();
  },
  watch: {
    $route() {
      this.menuPermission = [];
      this.verifyMenu();
      this.menuActive = false;
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    verifyMenu() {
      for(const item of this.menu) {
        for (const userRole of this.userRoles) {
          if(_.includes(item.roles.sort(), userRole.name)) {
            this.menuPermission.push(item);
            break;
          }
        }
      }
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if(this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        }
        else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      }
      else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false,
        'layout-theme-light': this.$appState.theme.startsWith('saga')
      }];
    },
    logo() {
      return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
    }
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    'AppFooter': AppFooter,
  }
}
</script>

<style scoped>

</style>
