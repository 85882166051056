import axios from "axios";


export default class CepService {

    async findCep(cep) {
        return axios.get(`https://brasilapi.com.br/api/cep/v2/${cep}`);
    }

}
