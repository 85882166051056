<template>

  <div class="surface-card px-4 py-8 md:px-6 lg:px-4">
    <div class="grid">
      <div class="col-12 p-1 mb-3 lg:mb-5 lg:px-4">
        <div class="text-900 font-bold text-6xl mb-4">Seu período gratuito finalizou!</div>
        <div class="text-700 text-xl line-height-3">Adquira agora nosso plano e contunue usando todos os recursos do Clinical Mind.</div>
      </div>
      <div class="col-12 lg:col-12">
        <div class="h-full pl-3">
          <div class="surface-card shadow-2 p-5" style="border-radius: 6px">
            <div class="flex align-items-center justify-content-center">
              <div class="field">
                <div class="field-radiobutton">
                  <RadioButton inputId="MENSAL" name="MENSAL" value="MENSAL" v-model="plano" />
                  <label class="mr-5" for="pf">Plano Mensal</label>
                  <RadioButton  inputId="ANUAL" name="ANUAL" value="ANUAL" v-model="plano" />
                  <label for="city2">Plano Anual</label>
                </div>
              </div>
            </div>
            <div class="flex flex-column mb-5 justify-content-between md:flex-row" v-if="plano === 'MENSAL'">
              <div class="flex-grow-1 lg:w-min">
                <div class="text-900 font-bold text-2xl mb-2">Plano Mensal</div>
              </div>
              <div class="flex align-items-center lg:w-15rem lg:justify-content-end mt-3 lg:mt-0">
                <span class="font-bold text-3xl text-900">R$ 499,99</span>
              </div>
            </div>
            <div class="flex flex-column mb-5 justify-content-between md:flex-row" v-if="plano === 'ANUAL'">
              <div class="flex-grow-1 lg:w-min">
                <div class="text-900 font-bold text-2xl mb-2">Plano Anual</div>
                <div class="text-500 text-xl">Desconto de 5% no plano anual.</div>
              </div>
              <div class="flex align-items-center lg:w-30rem lg:justify-content-end mt-3 lg:mt-0">
                <span class="font-bold text-3xl text-900">R$ 5.699,86</span>
              </div>
            </div>
            <div class="mt-0">
              <img src="@/assets/bandeiras_cartao.png" class="w-25rem">
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex flex-column justify-space-between md:flex-row mb-5">
              <ul class="list-none p-0 m-0 flex-grow-1">
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Gerenciamento de Contatos</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Controle de Estoque</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Análise Financeira</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Perfis de Acesso</span>
                </li>
              </ul>
              <ul class="list-none p-0 md:pl-2 m-0 flex-grow-1">
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Agendamento</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Compras Realizadas</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Anmesia</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Tudo em um só lugar</span>
                </li>
              </ul>
            </div>
            <div class="surface-card p-4 shadow-2 border-round p-fluid">
              <div class="grid formgrid p-fluid">
                <div class="field col-12 md:col-12">
                  <div class="field-radiobutton">
                    <RadioButton inputId="PF" name="PF" value="PF" v-model="customer.type" />
                    <label class="mr-5" for="pf">Pessoa Física</label>
                    <RadioButton  inputId="PJ" name="PJ" value="PJ" v-model="customer.type" />
                    <label for="city2">Pessoa Jurídica</label>
                  </div>
                </div>
                <div class="field mb-4 col-12 md:col-4" v-if="customer.type === 'PF'">
                  <label for="company_name" :class="{'p-error': v$.customer.document.$error}">CPF*</label>
                  <div class="p-inputgroup">
                    <InputMask
                        mask="999.999.999-99"
                        v-model.trim="customer.document"
                        @blur="v$.customer.document.$touch"
                        :class="{'p-invalid': v$.customer.document.$error}"
                        id="company_name"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.customer.document.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-12 md:col-4" v-if="customer.type === 'PJ'">
                  <label for="company_name" :class="{'p-error': v$.customer.document.$error}">CNPJ*</label>
                  <div class="p-inputgroup">
                    <InputMask
                        mask="99.999.999/9999-99"
                        v-model.trim="customer.document"
                        @blur="v$.customer.document.$touch"
                        :class="{'p-invalid': v$.customer.document.$error}"
                        id="company_name"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.customer.document.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field col-12 md:col-8"></div>
                <div class="field mb-4 col-6 md:col-4">
                  <label for="company_name" :class="{'p-error': v$.card.number.$error}">Número do Cartão*</label>
                  <div class="p-inputgroup">
                    <InputMask
                        mask="9999 9999 9999 9999"
                        v-model.trim="card.number"
                        @blur="v$.card.number.$touch"
                        :class="{'p-invalid': v$.card.number.$error}"
                        id="company_name"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.card.number.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field col-6 md:col-8"></div>
                <div class="field mb-4 col-4 md:col-2">
                  <label for="customer_name" :class="{'p-error': v$.expirationDate.$error}">Mês/Ano*</label>
                  <div class="p-inputgroup">
                    <InputMask mask="99/99"
                               id="customer_name"
                               v-model="expirationDate"
                               @blur="v$.expirationDate.$touch"
                               :class="{'p-invalid': v$.expirationDate.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.expirationDate.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-4 md:col-2">
                  <label for="customer_email" :class="{'p-error': v$.card.cvv.$error}">CVV*</label>
                  <div class="p-inputgroup">
                    <InputText id="customer_email"
                               v-model="card.cvv"
                               @blur="v$.card.cvv.$touch"
                               :class="{'p-invalid': v$.card.cvv.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.card.cvv.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-12 md:col-8">
                  <label for="invoice_id" :class="{'p-error': v$.card.holder_name.$error}">Nome Cartão*</label>
                  <div class="p-inputgroup">
                    <InputText id="invoice_id"
                               v-model="card.holder_name"
                               @blur="v$.card.holder_name.$touch"
                               :class="{'p-invalid': v$.card.holder_name.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.card.holder_name.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-9 md:col-3">
                  <label for="cep" :class="{'p-error': v$.billing_address.zip_code.$error}">CEP*</label>
                  <div class="p-inputgroup">
                    <InputMask mask="99999-999"
                               id="cep"
                               v-model="billing_address.zip_code"
                               @blur="v$.billing_address.zip_code.$touch"
                               :class="{'p-invalid': v$.billing_address.zip_code.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.billing_address.zip_code.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="col-12 md:col-2 align-items-center flex">
                  <Button icon="pi pi-search" label="Buscar" class="p-button" @click="findByCep"/>
                </div>
                <div class="field mb-4 md:col-7"></div>
                <div class="field mb-4 col-12 md:col-6">
                  <label for="email2" :class="{'p-error': v$.uf.$error}">Estado*</label>
                  <div class="p-inputgroup">
                    <Dropdown v-model="uf"
                              :options="ufs"
                              optionLabel="sigla"
                              placeholder="Selecione um Estado"
                              @blur="v$.uf.$touch"
                              :class="{'p-invalid': v$.uf.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.uf.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>

                <div class="field mb-4 col-12 md:col-6">
                  <label for="email2" :class="{'p-error': v$.billing_address.city.$error}">Cidade*</label>
                  <div class="p-inputgroup">
                    <InputText id="email2"
                               v-model="billing_address.city"
                               @blur="v$.billing_address.city.$touch"
                               :class="{'p-invalid': v$.billing_address.city.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.billing_address.city.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-12 md:col-5">
                  <label for="email2" :class="{'p-error': v$.billing_address.bairro.$error}">Bairro*</label>
                  <div class="p-inputgroup">
                    <InputText
                        v-model="billing_address.bairro"
                        @blur="v$.billing_address.bairro.$touch"
                        :class="{'p-invalid': v$.billing_address.bairro.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.billing_address.bairro.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-12 md:col-5">
                  <label for="email2" :class="{'p-error': v$.billing_address.line_1.$error}">Logradouro*</label>
                  <div class="p-inputgroup">
                    <InputText
                        v-model="billing_address.line_1"
                        @blur="v$.billing_address.line_1.$touch"
                        :class="{'p-invalid': v$.billing_address.line_1.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.billing_address.line_1.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
                <div class="field mb-4 col-12 md:col-5">
                  <label for="phone" :class="{'p-error': v$.phone.$error}">Celular*</label>
                  <div class="p-inputgroup">
                    <InputMask
                        mask="(99) 99999-9999"
                        v-model="phone"
                        @blur="v$.phone.$touch"
                        :class="{'p-invalid': v$.phone.$error}"
                    />
                  </div>
                  <small class="p-error" v-for="error of v$.phone.$errors"
                         :key="error.$uid">{{error.$message}}</small>
                </div>
              </div>
              <div class="flex justify-content-end align-items-end mt-5">
                <Button label="Pagar Agora" @click.prevent="save"></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import _ from "lodash";
import CepService from "@/service/CepService";
import BrasilService from "@/service/BrasilService";
import PaymentService from "@/service/PaymentService";
import {helpers, required} from "@vuelidate/validators";

export default {
  name: "PaymentNotFound",
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      card: {},
      billing_address: {},
      customer: {
        type: "PF"
      },
      expirationDate: "",
      ufs: [],
      uf: null,
      phone: null,
      cepService: null,
      brasilService: null,
      paymentService: null,
      plano: "MENSAL"
    }
  },
  validations() {
    return {
      card: {
        number: {
          required: helpers.withMessage('Campo Número do cartão é obrigatório.', required)
        },
        cvv: {
          required: helpers.withMessage("CVV é obrigatório.", required)
        },
        holder_name: {
          required: helpers.withMessage('Campo Nome é inválido.', required)
        }
      },
      customer: {
        document: {
          required: helpers.withMessage('Campo Documento é obrigatório.', required)
        },
      },
      billing_address: {
        zip_code: {
          required: helpers.withMessage('Campo CEP é obrigatório.', required)
        },
        city: {
          required: helpers.withMessage('Campo Cidade é obrigatório.', required)
        },
        bairro: {
          required: helpers.withMessage('Campo Bairro é obrigatório.', required)
        },
        line_1: {
          required: helpers.withMessage('Campo Logradouro é obrigatório.', required)
        },
      },
      expirationDate: {
        required: helpers.withMessage('Campo Validade é obrigatório.', required)
      },
      uf: {
        required: helpers.withMessage('Campo UF é obrigatório.', required)
      },
      phone: {
        required: helpers.withMessage('Campo Celular é obrigatório.', required)
      }
    }
  },
  created() {
    this.cepService = new CepService();
    this.brasilService = new BrasilService();
    this.paymentService = new PaymentService();
  },

  async mounted() {
    await this.findAllUf();
  },

  methods: {

    validarCartao() {
      const cartoes = {
        Visa: /^4[0-9]{12}(?:[0-9]{3})/,
        Mastercard: /^5[1-5][0-9]{14}/,
        Amex: /^3[47][0-9]{13}/,
        DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
        Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}/
      };

      for (const cartao in cartoes) {
        if (this.card.number.replace(/ /g, '').match(cartoes[cartao])) {
          return cartao;
        }
      }
      return false;
    },

    async findAllUf() {
      const ufss = [];
      const response = await this.brasilService.findEstados();
      if (response.status === 200) {
        response.data.forEach((uf) => {
          ufss.push(uf);
        });
        this.ufs = _.orderBy(ufss, ['sigla'], ['asc'])
      }
    },

    async findByCep() {
      const loader = this.$loading.show();
      const response = await this.cepService.findCep(this.billing_address.zip_code);
      if (response.status === 200) {
        this.billing_address.state = response.data.state;
        this.billing_address.city = response.data.city;
        this.billing_address.bairro = response.data.neighborhood;
        this.billing_address.line_1 = response.data.street;

        this.uf = _.find(this.ufs, ['sigla', this.billing_address.state]);
      }
      loader.hide();
    },

    async save() {
      this.billing_address.state = this.uf.sigla;
      this.billing_address.country = 'BR';
      this.card.billing_address = this.billing_address;
      const expiration = this.expirationDate.split("/");
      this.card.exp_month = expiration[0];
      this.card.exp_year = expiration[1];

      let phoneMask = this.phone.replace(/[^a-zA-Z0-9 ]/g, "");
      phoneMask = phoneMask.replace(" ", "");
      const phones = {
        mobile_phone: {
          country_code: "55",
          area_code: phoneMask.substr(0, 2),
          number: phoneMask.substr(2, 11),
        }
      };

      this.customer.document = this.customer.document.replace(/[^a-zA-Z0-9 ]/g, "");
      this.customer.phones = phones;

      const payload = {
        card: this.card,
        customer: this.customer,
        period: this.plano
      }

      const response = await this.paymentService.save(payload);
      if(response.status === 200) {
        this.$toast.add({severity:'success', summary: 'Sucesso', detail: response.data.message, life: 3000});
        this.$router.push({name: 'agenda-view'});
      } else {
        this.$toast.add({severity:'error', summary: 'Erro', detail: response.data.message, life: 6000});
      }
    }
  }
}
</script>

<style scoped>
</style>
