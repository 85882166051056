<template>
  <Toast>
  </Toast>
  <router-view />
</template>

<script>

import api from "../configs/api";

export default {
  data() {
    return {
      loader: null
    }
  },

  created() {

    // const firebaseConfig = {
    //   apiKey: "AIzaSyCzfiPUzKIfweSY-hNtjEZFvkk0JmY2Qmg",
    //   authDomain: "clinical-mind.firebaseapp.com",
    //   projectId: "clinical-mind",
    //   storageBucket: "clinical-mind.appspot.com",
    //   messagingSenderId: "472531378249",
    //   appId: "1:472531378249:web:f156391566be792b2fdf98",
    //   measurementId: "G-TWQN051730"
    // };

    // const firebase = initializeApp(firebaseConfig);

    // const messaging = getMessaging(firebase);
    // if (messaging.isSupported()) {
    //   Notification.requestPermission().then((permission) => {
    //     if (permission === 'granted') {
    //       console.log('Notification permission granted.');
    //     }
    //   });
    //
    //   getToken(messaging,
    //       {vapidKey: "BLi58ZUUzeFc8gckLe_oRbNYbkap1k6OMe3bQgerUetJJx6xrNdMJtp0pvj1Lumv1Tys3ciraPYGAh_6ZcWIwVM"})
    //       .then((currentToken) => {
    //         if (currentToken) {
    //           console.log("currentToken", currentToken);
    //           localStorage.setItem('FCMToken', currentToken.toString());
    //         } else {
    //           console.log('No registration token available. Request permission to generate one.');
    //         }
    //       }).catch((err) => {
    //     console.log('An error occurred while retrieving token. ', err);
    //   });
    //
    //   onMessage(messaging, (message) => {
    //     console.log(message);
    //   });
    // }

    api.interceptors.request.use(
        (config) => {
          if(config.url.includes("/auth/validation") || config.url === "/auth/update-password") {
            return config;
          }
          if (localStorage.getItem('access_token')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
            config.headers['clinical'] = localStorage.getItem("clinicalId");
          } else {
            this.$router.push({name: 'home'});
          }
          return config;
        }, (error) => {
          return Promise.reject(error);
        }
    )

    api.interceptors.response.use(
        (res) => {
          return Promise.resolve(res);
        }, async (err) => {

          const originalConfig = err.config;
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            localStorage.clear();
            await this.$router.push({name: 'home'});
            return err;
          }
          if(err.response.status === 403) {
            console.info(`ERR: ${err.response.status}`);
            await this.$router.push({name: 'agenda-view'});
            return;
          }
          if(err.response.status === 402) {
            await this.$router.push({name: 'paymentNotFound'});
            return err;
          }
        }
    );
  }

}

</script>

<style lang="scss">
</style>
