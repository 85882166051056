<template>
  <div class="layout-footer">
    <img alt="Logo" src="../assets/logo.png" height="50" class="mr-1" />
    <span class="font-medium ml-2">&copy; 2022 Clinical Mind, Inc. All rights reserved</span>
  </div>
  <span class="flex align-items-center justify-content-end text-sm">Versão: {{version}}</span>
</template>

<script>
import {version} from "./../../package.json";
export default {
  name: "AppFooter",
  mounted() {
    this.version = version;
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme ? 'images/logo.png' : 'images/logo.png';
    }
  },
  data() {
    return {
      version: version.version
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  }
}
</script>

<style scoped>

</style>
