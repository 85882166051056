<template>
  <div class="layout-menu-container">
    <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
  props: {
    model: Array
  },
  mounted() {
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  },
  components: {
    'AppSubmenu': AppSubmenu
  }
}
</script>

<style scoped>

</style>
